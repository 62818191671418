import React, { useState } from 'react'

export default function Header({ image }) {
    return (
        <div style={{ backgroundImage: `url(${image})` }} className={`bg-none bg-cover bg-center flex items-center justify-center w-[100%] lg:w-[103%] lg:p-5 lg:-mx-5`}>
            <link
                href="https://cdn.jsdelivr.net/npm/daisyui@2.6.0/dist/full.css"
                rel="stylesheet"
                type="text/css"
            />
            <div className='flex items-end gap-2 bg-red-600'>
                <div class="avatar lg:border-[10px] ">
                    <div class="w-full lg:w-[371px] lg:h-[371px]">
                        <img src={image} alt='' />
                    </div>
                </div>
                <div className='relative bg-red-500'>
                    <div class="flex flex-col">
                        <a class="h-12 w-12 text-white flex justify-center items-center bg-[#3b5998]" href="https://www.facebook.com/sharer/sharer.php?u=http://marsharaven.com"><i class="fa fa-facebook"></i></a>
                        <a class="h-12 w-12 text-white flex justify-center items-center bg-[#55acee]" href="https://twitter.com/intent/tweet?text=Catch Me: The Hits&amp;url=http://marsharaven.com/&amp;via=creativedevs"><i class="fa fa-twitter"></i></a>
                        <a class="h-12 w-12 text-white flex justify-center items-center bg-[#34526f]" href="https://www.tumblr.com/widgets/share/tool?canonicalUrl=https%3A%2F%2Fmarsharaven.com&title=Catch%20Me%3A%20The%20Hits"><i class="fa fa-tumblr"></i></a>
                        <a class="h-12 w-12 text-white flex justify-center items-center bg-[#c5282f]" href={`https://pinterest.com/pin/create/button/?url=http://marsharaven.com/&amp;description=Classic HiNRG, Electronica, Euro Pop
Highly recommend CD compilation of the numerous recordings and hits, by the classic HiNRG disco diva, Marsha Raven. Marsha was one of the most popular vocalists on the hugely influential HiNRG/Eurobeat dance music scene of the 80s and early 90s. Marsha had huge worldwide success, with hits like "Catch me" "False Alarm" and "Doctor DJ".&amp;media=image`}><i class="fa fa-pinterest"></i></a>
                        <a class="h-12 w-12 text-white flex justify-center items-center bg-[#a1caf2]" href="https://www.reddit.com/submit?url=https%3A%2F%2Fmarsharaven.com&title=Catch%20Me%3A%20The%20Hits%20by%20Marsha%20Raven"><i class="fa fa-reddit"></i></a>
                        <a class="h-12 w-12 text-white flex justify-center items-center bg-[#3b5998]" href="mailto:?subject=Have%20you%20heard%20Catch%20Me%3A%20The%20Hits%20by%20Marsha%20Raven%3F&body=Catch%20Me%3A%20The%20Hits%0Ahttps%3A%2F%2Fmarsharaven.com %0AClassic%20HiNRG%2C%20Electronica%2C%20Euro%20Pop%0AHighly%20recommend%20CD%20compilation%20of%20the%20numerous%20recordings%20and%20hits%2C%20by%20the%20classic%20HiNRG%20disco%20diva%2C%20Marsha%20Raven.%20Marsha%20was%20one%20of%20the%20most%20popular%20vocalists%20on%20the%20hugely%20influential%20HiNRG%2FEurobeat%20dance%20music%20scene%20of%20the%2080s%20and%20early%2090s.%20Marsha%20had%20huge%20worldwide%20success%2C%20with%20hits%20like%20%22Catch%20me%22%20%22False%20Alarm%22%20and%20%22Doctor%20DJ%22."><i class="fa fa-envelope"></i></a>

                    </div>
                </div>
                {/* </div> */}
            </div>
        </div >
    )
}
