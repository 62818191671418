import React from 'react'

export default function MusicBody() {


    return (
        <div className='grid w-full gap-5 grid-col-1 lg:grid-cols-3'>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/U2WVJHQollk?si=MNXzjIk6TfxR-v16" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/68FGW3-j6NY?si=hltJKEAZmovKr2yr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/Mly9u7jI9Q4?si=TsaT4gKStJTOIH8z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/Sk9lXu9QOQc?si=Ag3sKDhlX9pLvBeM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/46XnV6TUXos?si=ZI43y3aQTHakm_ZW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/VWjWzokbIQ0?si=gMWaXdftdC2498ID" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/MguA0t4XIYo?si=7R1xHpm7XJ6Dj5tC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/j816naHhX5c?si=iE1yICqWgGBkI_0k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/ZDkWqZsdD_o?si=bCstPlbBJjlrShKA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/BB8NVW0cmzc?si=ZpYEy3kyVnZfBbVF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/IioAPqeBxWM?si=_CRUSqtmST6BUenr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="300" src="https://www.youtube.com/embed/2yXBedcnvwc?si=YPuFZDKWSF68HXHN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    )
}
