import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import MusicBody from './components/MusicBody';
import Sidebar from './components/Sidebar';

function App() {
  return (
    <div className='min-w-screen min-h-screen'>
      <Header image={process.env.PUBLIC_URL + '/images/large.jpg'} />
      <div className='flex items-center justify-center p-5'>
        <button className="btn uppercase" onClick={() => document.getElementById('my_modal_1').showModal()}>Add to Bookmark</button>
        <dialog id="my_modal_1" className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Hello!</h3>
            <p className="py-4">Please Add this page on your Browser Using Bookmark</p>
            <div className="modal-action">
              <form method="dialog">
                <button className="btn">Close</button>
              </form>
            </div>
          </div>
        </dialog>
      </div>
      <div className='flex items-center justify-center'>
        <div className='w-full  flex flex-col lg:flex-row gap-2 p-5'>
          <Sidebar />
          <div className='w-full'>
            <MusicBody />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
