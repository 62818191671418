import React from 'react'

export default function Footer() {
    return (
        <>
            <div className='bg-gray-900 relative flex flex-col items-center mt-10'>
                <h1 className='absolute text-white bg-gray-900 px-10 it text-2xl  -top-5 rounded-full z-10'>More Releases by this Artist</h1>
                <div className='p-10 flex flex-col items-center justify-center'>
                    <a href="https://marsharaven.hearnow.com/best-of-marsha-raven-catch-me-im-falling-in-love">
                        <img src="/images/medium.jpg" alt="" />
                    </a>
                    <h1 className='mt-5 text-white text-xl'>Best of Marsha Raven: Catch Me (I'm Falling in Love)</h1>
                </div>
            </div>
            <p className='text-center p-4'>© 2021 - {new Date().getFullYear()} M White-Pritchard. All Rights Reserved. Worldwide.</p>
            {/* <div className='flex justify-center items-center'>
                <img className='w-[200px]' src="/images/hearnow_logo.min.svg" alt="" />
                <img src="/images/cdbaby_logo.min.min.svg" alt="" />
            </div> */}
        </>
    )
}
