import React from 'react'
import { BsSpotify } from 'react-icons/bs'
import { AiFillApple, AiOutlineAmazon, AiOutlineShoppingCart } from 'react-icons/ai'
import { FaItunes, FaDeezer } from 'react-icons/fa'
import { SiPandora } from 'react-icons/si'
import { TfiYoutube } from 'react-icons/tfi'

export default function Sidebar() {
    return (
        <div className='w-full lg:w-1/6 flex flex-col items-center mb-10'>
            <h1 className='text-4xl font-bold'>Catch Me: The Hits</h1>
            <h3 className='text-2xl font-bold'>by <a href='https://www.ebay.co.uk/itm/354279082403' target='_blank' className='underline italic'>Marsha Raven</a></h3>
            <h4 className='text-xl mb-5'>Get the music:</h4>
            <div className='w-52 flex flex-col justify-center'>
                <a className='border mb-3 rounded-full text-gray-900 hover:text-white hover:bg-gray-900 px-4 w-full py-1.5 gap-4 border-gray-700 flex items-center' href="https://open.spotify.com/album/5TzMCFq6uM2lSTMlGC8ZEM" target='blank'>
                    <BsSpotify className='' size={32} />
                    <span className='text-2xl font-bold'>Spotify</span>
                </a>
                <a className='border mb-3 rounded-full text-gray-900 hover:text-white hover:bg-gray-900 px-4 w-full py-1.5 gap-4 border-gray-700 flex items-center' href="https://music.apple.com/gb/album/catch-me-the-hits/1563380521" target='blank'>
                    <AiFillApple className='' size={32} />
                    <span className='text-2xl font-bold'>Apple</span>
                </a>
                <a className='border mb-3 rounded-full text-gray-900 hover:text-white hover:bg-gray-900 px-4 w-full py-1.5 gap-4 border-gray-700 flex items-center' href="https://music.apple.com/gb/album/catch-me-the-hits/1563380521" target='blank'>
                    <FaItunes className='' size={32} />
                    <span className='text-2xl font-bold'>iTunes</span>
                </a>
                <a className='border mb-3 rounded-full text-gray-900 hover:text-white hover:bg-gray-900 px-4 w-full py-1.5 gap-4 border-gray-700 flex items-center' href="https://www.amazon.com/dp/B0924VJ1SB?linkCode=osi&th=1&psc=1&tag=fndcmpgns-20" target='blank'>
                    <AiOutlineAmazon className='' size={32} />
                    <span className='text-2xl font-bold'>Amazon</span>
                </a>
                {/* <a className='border mb-3 rounded-full text-gray-900 hover:text-white hover:bg-gray-900 px-4 w-full py-1.5 gap-4 border-gray-700 flex items-center' href="https://open.spotify.com/album/5TzMCFq6uM2lSTMlGC8ZEM" target='blank'>
                    <SiPandora className='' size={32} />
                    <span className='text-2xl font-bold'>Pandora</span>
                </a> */}
                <a className='border mb-3 rounded-full text-gray-900 hover:text-white hover:bg-gray-900 px-4 w-full py-1.5 gap-4 border-gray-700 flex items-center' href="https://www.deezer.com/en/album/221952082" target='blank'>
                    <FaDeezer className='' size={32} />
                    <span className='text-2xl font-bold'>Deezer</span>
                </a>
            </div>
            <br />
            <a className=' border mb-3 text-gray-900 hover:text-white hover:bg-gray-900 px-4 py-1.5 gap-4 border-gray-700 flex items-center' href="https://marsharaven.hearnow.com/catch-me-the-hits" target='blank'>
                <AiOutlineShoppingCart className='' size={30} />
                <span className='text-xl font-bold'>Buy From Artist</span>
            </a>
            <h1 className='text-xl text-left my-4 font-bold'>Biography</h1>
            <p className='m-4 mt-5 lg:m-0 mb-10'>
                Marsha Raven is one of the most popular disco divas on the hugely influential Eurobeat, Hi-NRG, Electronica, Italo Disco dance music scene of the 80s and into the early 90s. She debuted in 1982 as a recording artist with her single ‘I Like Plastic’ by Pierre Tubbs on Red Bus Records. It was in 1984 the mega hit ‘Catch Me (I’m Falling in Love)' on Passion Records, produced by Ian Anthony Stephens established her as an international artiste in USA, Canada, Europe, South America, Mexico, Australia, South Africa, Russia and Japan. 'False Alarm' followed. Upon moving to Record Shack Records, she released her self-penned dance floor monster ‘Doctor DJ and scored 2 more dance hits with producer Ian Levine’s ‘Stranger in Disguise’ and ‘That Jealous Sensation’. In 1995, she teamed up again with Levine and released the album ‘The Best Of Marsha Raven’ which has now gone on to become an iconic 80s music perennial. In 2021, Marsha Raven released her eagerly awaited Greatest Hits album titled ‘Catch Me – The Hits’.
            </p>
            <br />
            <p className='mt-10 lg:m-0 mb-5'>Classic HiNRG, Electronica, Euro Pop. Released 2021 - {new Date().getFullYear()}</p>
        </div>
    )
}
